<template>
  <div>
    <k-editor
      v-if="!$apollo.loading"
      :collection="collection"
      :theme="theme"
      :content="content"
      :templateName="templateName"
      :snippets="snippets"
      :privileges="privileges"
      :group-settings="group.settings"
      :corporateIdentity="corporateIdentityData"
      :variablesPluginData="variablesPluginData"
      :chatGPTPluginData="chatGPTPluginData"
      :page-siblings="pageSiblings"
      :prev-next-page-siblings="prevNextPageSiblings"
      :custom-css="customCssOfParentElements"
      :is-sys-admin="isSysAdmin"
      :language="language"
      :page-language="pageLanguage"
      :personalized-data="personalizedData"
      :forms-data="formsData"
      @onidle="onidle"
      @get-editor-data="saveEditorContentInComponent"
    />
    <modal-preview-page
      v-if="isPreviewWindowOpen && iframeSource && itemName"
      :iframe-src="iframeSource"
      :preview-action-object="previewActionObject"
      :item-name="itemName"
      :group-domain="group.domain"
      :item-path="itemPath"
    />
    <modal-reload-page
      v-if="showReloadPage"
      class="d-inline-block"
      @reload-page="reloadPage"
      @cancel-reload-page="cancelReloadPage"
    ></modal-reload-page>
    <modal-show-url
      v-if="showShowUrlModal && group.domain"
      :slug="slugOfPage"
      :group-domain="group.domain"
      :slug-path="`${parentSlugPath}/${slugOfPage}`"
      pageType="page"
      :itemPath="itemPath"
      @close-modal="closeShowUrlModal"
    ></modal-show-url>
  </div>
</template>

<script>
import InsertNewPageVersion from '@/graphQlQueries/mutations/insertNewPageVersion';
import SetAutosaveIndicatorForHeaderInCache from '@/graphQlQueries/mutations/setAutosaveIndicatorForHeaderInCache';
import SetItemUrlWindowFromCache from '@/graphQlQueries/mutations/setItemUrlWindowFromCache.js';
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import SetPageHasToBePublishedInCache from '@/graphQlQueries/mutations/setPageHasToBePublishedInCache';
import SetPageNavigationInCache from '@/graphQlQueries/mutations/setPageNavigationInCache';
import GetItemUrlWindowFromCache from '@/graphQlQueries/queries/getItemUrlWindowFromCache';
import UpdatePagePublishFlag from '@/graphQlQueries/mutations/updatePagePublishFlag';
import UpdatePageVersionPublishStatus from '@/graphQlQueries/mutations/updatePageVersionPublishStatus';
import UpdatePageVersion from '@/graphQlQueries/mutations/updatePageVersion';
import InsertPublicationLogs from '@/graphQlQueries/mutations/insertPublicationLogs';
import GetCustomCssOfFoldersAndProjects from '@/graphQlQueries/queries/getCustomCssOfFoldersAndProjects';
import GetAllSiblingPagesOfCurrentPage from '@/graphQlQueries/queries/getAllSiblingPagesOfCurrentPage';
import GetLanguageById from '@/graphQlQueries/queries/getLanguageById';
import DeleteCacheFetchMagazine from '@/graphQlQueries/mutations/deleteCacheFetchMagazine';
import GetPageDataToPublish from '@/graphQlQueries/queries/getPageDataToPublish';
import GetPageHasToBePublishedFromCache from '@/graphQlQueries/queries/getPageHasToBePublishedFromCache';
import GetPageVersionData from '@/graphQlQueries/queries/getPageVersionData';
import GetPreviewWindowFromCache from '@/graphQlQueries/queries/getPreviewWindowFromCache';
import GetPublishOfParentProject from '@/graphQlQueries/queries/getPublishOfParentProject';
import GetPublishStatusOfLatestPageVersionsByPageId from '@/graphQlQueries/queries/getPublishStatusOfLatestPageVersionsByPageId';
import GetTemplateCollectionOfPage from '@/graphQlQueries/queries/getTemplateCollectionOfPage';
import GetPersonalizedVariableSetsItems from '@/graphQlQueries/queries/getPersonalizedVariableSetsItems';
import GetFormsByPath from '@/graphQlQueries/queries/forms/getFormsByPath';

import SendPublishedDataToServer from '@/mixins/sendPublishedDataToServer';
import { Mutex } from 'async-mutex';

export default {
  mixins: [SendPublishedDataToServer],
  components: {
    KEditor: () => import('@/components/helper/KEditor.vue'),
    ModalPreviewPage: () => import('@/components/modals/ModalPreviewPage.vue'),
    ModalReloadPage: () => import('@/components/modals/ModalReloadPage.vue'),
    ModalShowUrl: () => import('@/components/modals/ModalShowUrl.vue'),
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    pageId: {
      type: Number,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    privileges: {
      type: Array,
      required: true,
    },
    itemName: {
      type: String,
      default: null,
    },
    itemPath: {
      type: String,
      default: null,
    },
    isSysAdmin: {
      type: Boolean,
      default: false,
    },
    language: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    isPreviewWindowOpen: false,
    showShowUrlModal: false,
    versionNumber: null,
    currentLatestVersionId: null,
    contentFromDb: null,
    collection: null,
    theme: null,
    templateName: null,
    defaultContent: null,
    displayData: null,
    snippets: null,
    corporateIdentityData: null,
    variablesPluginData: null,
    chatGPTPluginData: null,
    parentSlugPath: null,
    slugOfPage: null,
    iframeSource: null,
    previewActionObject: null,
    publishOfParentProject: null,
    hasParentProject: false,
    parentProjectIsPublished: false,
    pageSiblings: null,
    prevNextPageSiblings: null,
    pageIsPublished: null,
    runGetAllSiblingPagesOfCurrentPage: true,
    customCssOfParentElements: null,
    parentFolderAndProjectArray: null,
    pageLanguage: null,
    getPersonalizedVariablesSetData: null,
    personalizedData: [],
    formsData: [],
    showReloadPage: false,
    mutex: null,
  }),
  created() {
    this.$apollo.mutate({
      mutation: SetLoadingIndicator,
      variables: {
        isIndicatorLoading: true,
      },
    });
    this.slugOfPage = (this.$route.params.parametersOfUrl)
      ? this.$route.params.parametersOfUrl.split('/').slice(-1).pop()
      : null;
    this.parentSlugPath = (this.$route.params.parametersOfUrl)
      ? this.$route.fullPath.substring(
        0, this.$route.fullPath.lastIndexOf('/'),
      )
      : null;

    this.createParentFolderAndProjectArray();
    this.mutex = new Mutex();
  },
  computed: {
    content() {
      return (this.contentFromDb) ? this.contentFromDb : this.defaultContent;
    },
  },
  apollo: {
    isPreviewWindowOpen: {
      query: GetPreviewWindowFromCache,
      update(data) {
        if (data.previewwWindow
          && data.previewwWindow.isPreviewWindowOpen) {
          // the preview button is pressed so call the preview modal window
          return true;
        }
        return false;
      },
    },
    showShowUrlModal: {
      query: GetItemUrlWindowFromCache,
      update(data) {
        if (data.itemUrlWindow?.openItemUrlWindow === true) {
          // the show url button is pressed so call the show url modal window
          return true;
        }
        return false;
      },
    },
    customCssOfParentElements: {
      query: GetCustomCssOfFoldersAndProjects,
      variables() {
        return {
          folderIds: this.parentFolderAndProjectArray.folderIds,
          projectIds: this.parentFolderAndProjectArray.projectIds,
        };
      },
      update(data) {
        let customCss = '';
        if (data.folders.length) {
          data.folders.forEach((folder) => {
            customCss = customCss + folder.custom_css + '\n';
          });
        }
        if (data.projects.length) {
          data.projects.forEach((project) => {
            customCss = customCss + project.custom_css + '\n';
          });
        }
        return customCss;
      },
      skip() {
        return !this.parentFolderAndProjectArray;
      },
      fetchPolicy: 'network-only',
    },
    templateData: {
      query: GetTemplateCollectionOfPage,
      variables() {
        return {
          pageId: this.pageId,
          groupId: this.group.group_id,
        };
      },
      update(data) {
        console.warn('this', this);
        console.log('snippets', this.snippets, data.pages[0]);
        if (data && data.pages && data.pages.length) {
          if (data.pages[0]?.parent_project?.plugins.length) {
            data.pages[0].parent_project.plugins.forEach((item) => {
              if(item.handle == 'plugin_variables') this.variablesPluginData = item.settings;
              if(item.handle == 'plugin_chatGPT') this.chatGPTPluginData = item.settings;
            })
          } else if (data.pages[0]?.parent_folder?.plugins.length) {
            data.pages[0].parent_folder.plugins.forEach((item) => {
              if(item.handle == 'plugin_variables') this.variablesPluginData = item.settings;
              if(item.handle == 'plugin_chatGPT') this.chatGPTPluginData = item.settings;
            })
          }
          if (data.pages[0]?.parent_project?.corporate_identity) {
            // get the CI data if the page is in a project
            this.corporateIdentityData =
              data.pages[0].parent_project.corporate_identity;
          } else if (data.pages[0]?.parent_folder?.corporate_identity) {
            this.corporateIdentityData =
              data.pages[0].parent_folder.corporate_identity;
          }
          if (data.pages[0]?.parent_project?.theme) {
            // set the theme name
            this.theme = data.pages[0].parent_project.theme.name;
          }
          if (data.pages[0].template) {
            // set the collection name
            this.collection = data.pages[0].template.template_collections_templates[0].template_collection.name;
            // set the template name
            this.templateName = data.pages[0].template.name;
            // set the template default string
            this.defaultContent = data.pages[0].template.data.html;
            // get all the snippets
            if (data.pages[0].template.templates_snippets) {
              this.snippets = data.pages[0].template.templates_snippets.map((snippetItem) =>
                snippetItem.snippet.data.snippets,
              );
            }
          }
        }
      },
      fetchPolicy: 'network-only',
    },
    personalizedData: {
      query: GetPersonalizedVariableSetsItems,
      variables() {
        return {
          itemPath: this.itemPath,
        };
      },
      update(data) {
        console.log('personalised data', data.getPersonalizedVariableSetsByPath);
        return data.getPersonalizedVariableSetsByPath.length
          ? data.getPersonalizedVariableSetsByPath
          : [];
      },
      skip() {
        return !this.itemPath;
      },
      fetchPolicy: 'network-only',
    },
    formsData: {
      query: GetFormsByPath,
      variables() {
        return {
          itemPath: this.itemPath,
        };
      },
      update(data) {
        console.log('forms data', data);
        return data.getFormsByPath.length
            ? data.getFormsByPath
            : [];
      },
      skip() {
        return !this.itemPath;
      },
      fetchPolicy: 'network-only',
    },
    publishOfParentProject: {
      query: GetPublishOfParentProject,
      variables() {
        return {
          pageId: this.pageId,
        };
      },
      update(data) {
        this.hasParentProject = data.pages_by_pk.parent_project !== null;
        if (data.pages_by_pk.parent_project !== null) {
          this.parentProjectIsPublished = data.pages_by_pk.parent_project.published;
        }
        return data;
      },
      skip() {
        return !this.pageId;
      },
      fetchPolicy: 'network-only',
    },
    // get the complete data from page version
    contentFromDb: {
      query: GetPageVersionData,
      variables() {
        return {
          pageId: this.pageId,
        };
      },
      update(data) {
        if (data.pages_by_pk) {
          const pageData = data.pages_by_pk;
          this.pageIsPublished = pageData.published;
          this.pageLanguage = (pageData.language) ? pageData.language.handle : this.language;

          if (this.pageIsPublished !== true) {
            if (this.hasParentProject && this.parentProjectIsPublished) {
              this.setHeaderForButtonsForPublishPage(false, false);
            }
            if (!this.hasParentProject) {
              this.setHeaderForButtonsForPublishPage(false, false);
            }
          }
          if (pageData.latest_page_version) {
            this.versionNumber = pageData.latest_page_version.version_number;
            this.currentLatestVersionId = pageData.latest_page_version.page_version_id;
            if (pageData.latest_page_version.published === false) {
              // run the publish header query!!!
              this.setHeaderForButtonsForPublishPage(true, true);
            }
            return pageData.latest_page_version.editor_data.content;
          }
        }
        this.pageIsPublished = false;
        return null;
      },
      skip() {
        return !this.publishOfParentProject;
      },
      fetchPolicy: 'network-only',
    },
    pagePublishQueryFromCache: {
      query: GetPageHasToBePublishedFromCache,
      update(data) {
        if (
          data.pageHasToBePublished.isPublished &&
          data.pageHasToBePublished.publishButtonIsPressed
        ) {
          // this means the publish button is pressed so call the publish function
          this.publishContent();
        }
      },
    },
    dataFromTheDBForPublishing: {
      query: GetPageDataToPublish,
      variables() {
        return {
          pageId: this.pageId,
        };
      },
      update(data) {
        // build preview link
        this.buildPreviewLink(data.pages_by_pk.slug);
        return data.pages_by_pk;
      },
      skip() {
        return !this.pageId;
      },
      fetchPolicy: 'network-only',
    },
    allSiblingPagesOfCurrentPage: {
      query: GetAllSiblingPagesOfCurrentPage,
      variables() {
        return {
          pageId: this.pageId,
        };
      },
      update(data) {
        let pageSiblingsArray = [];
        console.log('allSiblings', data);
        if (data.pages_by_pk.parent_folder !== null) {
          pageSiblingsArray = this.createPageSiblingsArray(data.pages_by_pk.parent_folder.child_pages);
        } else if (data.pages_by_pk.parent_project !== null) {
          pageSiblingsArray = this.createPageSiblingsArray(data.pages_by_pk.parent_project.child_pages);
        }
        this.runGetAllSiblingPagesOfCurrentPage = false;
        this.pageSiblings = pageSiblingsArray;
        this.prevNextPageSiblings = this.createPrevNextSiblings(pageSiblingsArray);
        this.createPageNavigationLinks(pageSiblingsArray);

        return pageSiblingsArray;
      },
      skip() {
        return !this.pageId || !this.runGetAllSiblingPagesOfCurrentPage;
      },
      fetchPolicy: 'network-only',
    },

  },
  methods: {
    async onidle() {
      //console.log('on idle');
      // const { data: { pages_by_pk: { page_versions: [pageVersionItem] } } } = await this.$apollo.query({
      //   query: GetPublishStatusOfLatestPageVersionsByPageId,
      //   variables: {
      //     pageId: this.pageId,
      //   },
      //   fetchPolicy: 'network-only',
      // });
      // const pageVersionId = (pageVersionItem && pageVersionItem.page_version_id)
      //     ? pageVersionItem.page_version_id
      //     : null;
      // const pageVersionNumber = (pageVersionItem && pageVersionItem.version_number)
      //     ? pageVersionItem.version_number
      //     : null;
      // //console.log('on idle', pageVersionNumber, this.versionNumber);
      // if (pageVersionItem && pageVersionId !== this.currentLatestVersionId || pageVersionNumber !== this.versionNumber) {
      //   this.showReloadPage = true;
      // }
    },
    closeShowUrlModal() {
      this.$apollo.mutate({
        mutation: SetItemUrlWindowFromCache,
        variables: {
          openItemUrlWindow: false,
          itemPath: null,
        },
      });
    },
    reloadPage() {
      this.showReloadPage = false;
      this.$router.go(0);
    },
    cancelReloadPage() {
      this.showReloadPage = false;
    },
    createParentFolderAndProjectArray() {
      const itemPathIds = this.itemPath.split('.');
      let returnObject = {
        folderIds: [],
        projectIds: [],
      };
      itemPathIds.forEach((item) => {
        if (item.includes('f')) {
          returnObject.folderIds.push(parseInt(item.substring(1), 10));
        }
        if (item.includes('pr')) {
          returnObject.projectIds.push(parseInt(item.substring(2), 10));
        }
      });
      this.parentFolderAndProjectArray = returnObject;
    },
    createPageNavigationLinks(siblingArray) {
      let prevLinkSlug = null;
      let nextLinkSlug = null;

      // sort the array depending on order index
      siblingArray.sort((a, b) => a.order - b.order);
      // find the index of the current element
      const currentItemIndex = siblingArray.findIndex((item) => item.slug === this.slugOfPage);
      if (currentItemIndex !== -1) {
        // prepare the prev link
        if (currentItemIndex > 0) {
          prevLinkSlug = siblingArray[currentItemIndex - 1].slug;
        }
        // prepare the next link
        if (currentItemIndex < siblingArray.length - 1) {
          nextLinkSlug = siblingArray[currentItemIndex + 1].slug;
        }
      }
      // prepare the complete link
      // take the full path and remove the page slug
      const fullPath = this.$route.fullPath;
      const pathWithoutPageSlug = fullPath.substr(0, fullPath.lastIndexOf('/') + 1);
      // store the data in the apollo cache
      const prevLink = (prevLinkSlug) ? `${pathWithoutPageSlug}${prevLinkSlug}` : null;
      const nextLink = (nextLinkSlug) ? `${pathWithoutPageSlug}${nextLinkSlug}` : null;
      this.$apollo.mutate({
          mutation: SetPageNavigationInCache,
          variables: {
            prevLink,
            nextLink,
          },
        },
      );
    },
    createPageSiblingsArray(siblingDataArray) {
      console.log('sibling item', siblingDataArray);
      let siblings = [];
      siblingDataArray.forEach((siblingItem) => {
        let image = {};
        const pageTags = (siblingItem.pages_tags)
          ? siblingItem.pages_tags.map((item) => item.tag.name)
          : null;
        if (siblingItem.thumbnails?.image?.url) {
          image.url = this.$makeCloudImage(
            siblingItem.thumbnails.image.url,
            { width: 450, height: 300 },
            siblingItem.thumbnails.image,
          );
        }
        if (siblingItem.thumbnails?.image?.focalpoint) image.focalpoint = siblingItem.thumbnails?.image?.focalpoint;
        if (siblingItem.thumbnails?.image?.height) image.height = siblingItem.thumbnails?.image?.height;
        if (siblingItem.thumbnails?.image?.width) image.width = siblingItem.thumbnails?.image?.width;
        siblings.push({
          pageId: siblingItem.page_id,
          title: siblingItem.name,
          description: siblingItem.description,
          isPublished: siblingItem.published,
          image,
          isHidden: !siblingItem.listed,
          slug: siblingItem.slug,
          order: siblingItem.order_index,
          keywords: siblingItem.keywords,
          tags: pageTags,
        });
      });
      return siblings;
    },
    createPrevNextSiblings(siblingsArray) {
      // sort the array after the order
      const sortedSiblingsArray = siblingsArray.sort((a, b) => a.order > b.order && 1 || -1);
      const currentElementIndex = sortedSiblingsArray.findIndex((element) => element.pageId === this.pageId);
      const prev = (currentElementIndex < 1)
        ? null
        : {
          name: sortedSiblingsArray[currentElementIndex - 1].title,
          description: sortedSiblingsArray[currentElementIndex - 1].description,
          slug: sortedSiblingsArray[currentElementIndex - 1].slug,
          thumbnails: sortedSiblingsArray[currentElementIndex - 1].image,
        };
      const next = (currentElementIndex >= sortedSiblingsArray.length - 1)
        ? null
        : {
          name: sortedSiblingsArray[currentElementIndex + 1].title,
          description: sortedSiblingsArray[currentElementIndex + 1].description,
          slug: sortedSiblingsArray[currentElementIndex + 1].slug,
          thumbnails: sortedSiblingsArray[currentElementIndex + 1].image,
        };
      return { prev, next };
    },
    buildPreviewLink(slug) {
      if (slug) {
        this.previewActionObject = {
          slug: this.slugOfPage,
          groupSlug: this.group.slug,
          parentSlugPath: this.parentSlugPath,
        };

        this.iframeSource = (this.parentSlugPath)
          ? `${this.group.domain}${this.parentSlugPath}/${slug}`
          : `${this.group.domain}/${slug}`;
      }
    },
    async autoSaveData() {
      try {
        // get the data from the db
        const { data: { pages_by_pk: { page_versions: [pageVersionItem] } } } = await this.$apollo.query({
          query: GetPublishStatusOfLatestPageVersionsByPageId,
          variables: {
            pageId: this.pageId,
          },
          fetchPolicy: 'network-only',
        });
        const versionIsPublished = pageVersionItem && pageVersionItem.published;
        const pageVersionId = (pageVersionItem && pageVersionItem.page_version_id)
          ? pageVersionItem.page_version_id
          : null;
        const pageVersionNumber = (pageVersionItem && pageVersionItem.version_number)
          ? pageVersionItem.version_number
          : null;
        console.log('perversion, number', pageVersionId, pageVersionNumber, this.currentLatestVersionId, this.versionNumber);
        if (!pageVersionItem || (versionIsPublished && pageVersionId === this.currentLatestVersionId && pageVersionNumber === this.versionNumber)) {
          this.versionNumber = this.versionNumber + 1;
          // create a new row
          const { data: {
            insert_page_versions: {
              returning: [
                { page_version_id },
              ]
            }
          } } = await this.$apollo.mutate({
            mutation: InsertNewPageVersion,
            variables: {
              pageId: this.pageId,
              versionNumber: this.versionNumber,
              editorData: {
                content: this.editorData,
              },
              displayData: {
                content: this.displayData,
              },
              userId: this.userId,
            },
          });
          this.currentLatestVersionId = page_version_id;
        } else {
          if (pageVersionId && pageVersionId === this.currentLatestVersionId && pageVersionNumber === this.versionNumber) {
            this.versionNumber = this.versionNumber + 1;
            // update the row
            await this.$apollo.mutate({
              mutation: UpdatePageVersion,
              variables: {
                pageVersionId: pageVersionId,
                versionNumber: this.versionNumber,
                editorData: {
                  content: this.editorData,
                },
                displayData: {
                  content: this.displayData,
                },
                userId: this.userId,
              },
            });
          } else {
            this.showReloadPage = true;
          }
        }

        if (this.pageIsPublished) {
          await this.setHeaderForButtonsForPublishPage(this.pageIsPublished, true);
        } else {
          await this.setHeaderForButtonsForPublishPage(this.pageIsPublished, false);
        }

        await this.$apollo.mutate({
          mutation: SetAutosaveIndicatorForHeaderInCache,
          variables: {
            isSavingData: false,
            allSaved: true,
          },
        });
      } catch (ex) {
        this.versionNumber = this.versionNumber - 1;
        console.error(ex);
      }
    },
    async saveEditorContentInComponent(editorContent) {
      // save the published data from the component
      this.displayData = editorContent.displayData;
      if (editorContent.editorData !== null) {
        this.editorData = editorContent.editorData;
        // call the autosave
        await this.mutex.runExclusive(async () => {
          await this.autoSaveData();
        });
      }
    },
    async setHeaderForButtonsForPublishPage(isPublished, hasChanged) {
      try {
        await this.$apollo.mutate({
          mutation: SetPageHasToBePublishedInCache,
          variables: {
            isPublished,
            hasChanged,
            publishButtonIsPressed: false,
            hasParentProject: this.hasParentProject,
            parentProjectIsPublished: this.parentProjectIsPublished,
          },
        });
      } catch (ex) {
        console.error(ex);
      }
    },
    async publishContent() {
      // update the database with the query
      const dataToBeSendToTheServer = this.dataFromTheDBForPublishing;
      const languageId = (dataToBeSendToTheServer.language_id)
        ? dataToBeSendToTheServer.language_id
        : null;
      // get the name of the locale by the locale id
      let result = null;
      if (languageId) {
        result = await this.$apollo.query({
          query: GetLanguageById,
          variables: { languageId },
        });
      }
      dataToBeSendToTheServer.locale = (result?.data?.languages_by_pk?.handle)
        ? result.data.languages_by_pk.handle
        : null;
      dataToBeSendToTheServer.published = true;
      if (dataToBeSendToTheServer.parent_folder?.corporate_identity) {
        dataToBeSendToTheServer.corporate_identity = JSON.parse(JSON.stringify(
          dataToBeSendToTheServer.parent_folder.corporate_identity,
        ));
        delete dataToBeSendToTheServer.parent_folder;
      } else if (dataToBeSendToTheServer.parent_project?.corporate_identity) {
        dataToBeSendToTheServer.corporate_identity = JSON.parse(JSON.stringify(
          dataToBeSendToTheServer.parent_project.corporate_identity,
        ));
        delete dataToBeSendToTheServer.parent_project;
      }
      // add the current page version data!
      dataToBeSendToTheServer.latest_page_version = {
        display_data: {
          content: this.displayData,
        },
        versionNumber: this.versionNumber,
      };

      try {
        const publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
          action: 'publishPage',
          data: {
            parent_slug_path: this.parentSlugPath,
            group_slug: this.group.slug,
            ...dataToBeSendToTheServer,
          },
        });
        if (publishedDataWasSuccessfulSend) {
          // set the page status to published
          await this.$apollo.mutate({
            mutation: UpdatePagePublishFlag,
            variables: {
              pageIds: [this.pageId],
              isPublished: true,
            },
          });
          const { data: { pages_by_pk: { page_versions: [pageVersionItem] } } } = await this.$apollo.query({
            query: GetPublishStatusOfLatestPageVersionsByPageId,
            variables: {
              pageId: this.pageId,
            },
            fetchPolicy: 'network-only',
          });
          const pageVersionReturn = await this.$apollo.mutate({
            mutation: UpdatePageVersionPublishStatus,
            variables: {
              pageVersionId: pageVersionItem.page_version_id,
              userId: this.userId,
              published: true,
            },
          });
          const pageVersionUpdateDate = (pageVersionReturn.data?.update_page_versions?.returning[0].updated_at)
            ? pageVersionReturn.data.update_page_versions.returning[0].updated_at
            : null;
          const publicationLogs = [];
          publicationLogs.push({
            page_id: this.pageId,
            page_version_id: pageVersionItem.page_version_id,
            published_at: pageVersionUpdateDate,
            user_id: this.userId,
          });
          // mutation to write the insert statements for logging the published content
          await this.$apollo.mutate({
            mutation: InsertPublicationLogs,
            variables: {
              objects: publicationLogs,
            },
          });
          // delete the cache
          const domain = this.group.domain;
          let parent_slug_path = this.parentSlugPath;
          if (parent_slug_path === '/') {
            parent_slug_path = '';
          } else if (parent_slug_path.length > 1 && parent_slug_path[0] === '/') {
            parent_slug_path = parent_slug_path.substring(1, parent_slug_path.length);
          }
          let slug_path = `${parent_slug_path ? `${parent_slug_path}/` : ''}${this.slugOfPage}`;
          slug_path = slug_path.substring(0, slug_path.lastIndexOf('/'));
          await this.$apollo.mutate({
            mutation: DeleteCacheFetchMagazine,
            variables: {
              domain: (domain[domain.length - 1] === '/') ? domain : `${domain}/`,
              slug_path,
            },
          });

          // reset the publish state
          await this.$apollo.mutate({
            mutation: SetPageHasToBePublishedInCache,
            variables: {
              isPublished: true,
              hasChanged: false,
              publishButtonIsPressed: false,
              hasParentProject: this.hasParentProject,
              parentProjectIsPublished: this.parentProjectIsPublished,
            },
          });
        }
      } catch (ex) {
        console.error(ex);
      }
    },
    async publishContent2() {
      console.log('publish content');
      const { data: { pages_by_pk: { page_versions: [pageVersionItem] } } } = await this.$apollo.query({
        query: GetPublishStatusOfLatestPageVersionsByPageId,
        variables: {
          pageId: this.pageId,
        },
        fetchPolicy: 'network-only',
      });
      const pageVersionId = (pageVersionItem && pageVersionItem.page_version_id)
          ? pageVersionItem.page_version_id
          : null;
      const pageVersionNumber = (pageVersionItem && pageVersionItem.version_number)
          ? pageVersionItem.version_number
          : null;
      console.log('publish content', pageVersionNumber, this.versionNumber);
      if (!pageVersionItem || (pageVersionItem && pageVersionId === this.currentLatestVersionId && pageVersionNumber === this.versionNumber)) {
        // update the database with the query
        const dataToBeSendToTheServer = this.dataFromTheDBForPublishing;
        const languageId = (dataToBeSendToTheServer.language_id)
            ? dataToBeSendToTheServer.language_id
            : null;
        // get the name of the locale by the locale id
        let result = null;
        if (languageId) {
          result = await this.$apollo.query({
            query: GetLanguageById,
            variables: {languageId},
          });
        }
        dataToBeSendToTheServer.locale = (result?.data?.languages_by_pk?.handle)
            ? result.data.languages_by_pk.handle
            : null;
        dataToBeSendToTheServer.published = true;
        if (dataToBeSendToTheServer.parent_folder?.corporate_identity) {
          dataToBeSendToTheServer.corporate_identity = JSON.parse(JSON.stringify(
              dataToBeSendToTheServer.parent_folder.corporate_identity,
          ));
          delete dataToBeSendToTheServer.parent_folder;
        } else if (dataToBeSendToTheServer.parent_project?.corporate_identity) {
          dataToBeSendToTheServer.corporate_identity = JSON.parse(JSON.stringify(
              dataToBeSendToTheServer.parent_project.corporate_identity,
          ));
          delete dataToBeSendToTheServer.parent_project;
        }
        // add the current page version data!
        dataToBeSendToTheServer.latest_page_version = {
          display_data: {
            content: this.displayData,
          },
          versionNumber: this.versionNumber,
        };

        try {
          const publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
            action: 'publishPage',
            data: {
              parent_slug_path: this.parentSlugPath,
              group_slug: this.group.slug,
              ...dataToBeSendToTheServer,
            },
          });
          if (publishedDataWasSuccessfulSend) {
            // set the page status to published
            await this.$apollo.mutate({
              mutation: UpdatePagePublishFlag,
              variables: {
                pageIds: [this.pageId],
                isPublished: true,
              },
            });
            const {data: {pages_by_pk: {page_versions: [pageVersionItem]}}} = await this.$apollo.query({
              query: GetPublishStatusOfLatestPageVersionsByPageId,
              variables: {
                pageId: this.pageId,
              },
              fetchPolicy: 'network-only',
            });
            await this.$apollo.mutate({
              mutation: UpdatePageVersionPublishStatus,
              variables: {
                pageVersionId: pageVersionItem.page_version_id,
                userId: this.userId,
                published: true,
              },
            });

            // reset the publish state
            await this.$apollo.mutate({
              mutation: SetPageHasToBePublishedInCache,
              variables: {
                isPublished: true,
                hasChanged: false,
                publishButtonIsPressed: false,
                hasParentProject: this.hasParentProject,
                parentProjectIsPublished: this.parentProjectIsPublished,
              },
            });
          }
        } catch (ex) {
          console.error(ex);
        }
        await this.$apollo.queries.contentFromDb.refetch();
      } else {
        this.$bvModal.show('modal-reload-page');
      }
    },
  },
  beforeDestroy() {
    this.setHeaderForButtonsForPublishPage(true, false);
  },
};
</script>
